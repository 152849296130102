.text-welcome-semtech-mobile {
    height: 14vh;
    position: absolute;
    font-family: 'Fira Sans';
    background-color: #ffffffdc;
    text-align: center;
    margin: 26vh 0 10vh 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
}
.message-welcome-mobile >p {
    margin: 6%;
    color: #00AEEF;
    font-family: 'Fira Sans';

}
.titleMobileWhatsNew {
    color: #333;
    font-weight: 500;
    font-family: 'Fira Sans';
    font-size: 2.2vh;
}
.subtitleMobileWhatsNew {
    color: #333;
    margin-top: 1%;
    font-family: 'Fira Sans';
    font-weight: 500;
}

.whatsNewBox-mobile {
    width: 100%;
    top:72vh;
    background-color: #ffffff;
    color: #00AEEF;
    overflow-y: hidden;
    overflow-x: hidden;
    font-size: 1.8rem;
    padding-bottom: 2%;
}

.buttonCarousselNewsLP {
    text-align: center;
    background-color: #00AEEF;
    color: #FFF;
    width: 100vw;
    display: flex;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    margin: 0;
    height: 100%;
    width: 30%;
    text-decoration: none;
    margin-top: 5% !important;
    justify-content: center;
    align-content: center;
    align-items: center;
}


@keyframes fadeIn {
    0%{ opacity:0 }
    100%{ opacity: 1 }
}

.noticeMobileLP > h1 {
    border-left: 3px solid #00aeef;
    padding-left: 2%;
    width: 85%;
    margin-left: 5%;
    font-family: "Fira Sans";
    font-size:100%;
    font-weight:400 ;
}

.MessagesContainerMobile{
    display: flex;
    align-items: flex-end;
}

.whatsNewBox-mobile  > h3 {
    font-size: 3vh;
    padding-left: 2%;
    color: #333;
    margin: 0;
    margin-top: 5%;
    margin-left:5%;
}
