
.whatsNewSelectMobile{
    padding-right: 20px;
    cursor: pointer;
    padding-left: 20px !important;
    height: auto;
    min-width: 30vw;
    border:solid 1px #00aeef;
    border-radius: 5px;
    margin-bottom: 20%;
}
