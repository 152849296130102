@media only screen and (max-width: 1490px) {
    :root{
      font-size: 10px;
    }
}
.section3{

    display: grid;
    padding-top: 5%;
    padding-bottom: 6%;
}
.section1-title{
    width: 70%;
    text-align: center;
    margin:  0 auto;
    color: #00aeef;
    font-family: 'Fira sans', 'sans-serif';
    font-weight: 500;
    font-size: 2.2rem;
    margin-top: 3%;
}
.section1-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    margin-bottom: 2%;
}
.section1-img{
    width: 80% !important;
}

.section1-text{
    color: #333;
    font-size: 1.3125rem;
    align-content: center;
    font-family: 'Fira sans', 'sans-serif';
    padding-left: 5%;
    padding-right: 8%;
}
.text-padding{
    padding-left: 10%;
    display: flex;
    flex-direction: row;
}
.text-padding img{
    width: 12%;
    text-align: center;
}
.sign-up{
    background-color: #00aeef;
    color: #ffffff;
    border-style: none;
    padding: 0.4375em;
    padding-left: 3%;
    padding-right: 3%;
    transition: .3s background-color;
    border: 1px solid #00aeef;
    font-family:'Fira sans', 'sans-serif';
    cursor: pointer;
    font-size: .9rem;
    font-weight:lighter;
}

.sign-up:hover{
    color: #00aeef;

    background-color: #ffffff;
    border: 1px solid #00aeef
}
.section1-tracking-type-description{
    width: 50%;
    font-size: 1.3125rem;
    margin-top: 6% !important;
    margin:  0 auto;
    text-align: center;
    font-family:'Fira sans', 'sans-serif';
    color: #333;

}

.section1-tracking-types{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.section1-setup-types{
    margin-left: -8.5%;
}

.section2{
    width: 100%;
    margin-bottom: 3.5%;
    margin-top: 6%;
}
.description-gnss-wi-fi img {
    width: 50%;
    height: 50vh;
}
.description-gnss-wi-fi{
    display: flex;
    width: 93.5%;
}
.text-gnss-wi-fi{


    color: #ffffff;
    text-align: center;
    font-size: 1rem;
    background-color: #00aeef;

}
.text-gnss-wi-fi img{
    width: 50%;

}
.number-one{
    position: absolute;
    left: 33.5%;
    bottom: 15.6vh;
    font-size: 1.25rem;
    font-weight: 500;
}

.number-two{
    position: absolute;
    right: 33.5%;
    bottom: 15vh;
    font-size: 1.25rem;
    font-weight: 500;
}
.geolocation-gnss-wi-fi{
    display:  flex;
    flex-direction: row;
    margin: 0 auto;
    width: 60%;
    font-family:'Fira sans', 'sans-serif';
    font-size: 0.6rem;
}
.title-gnss-wi-fi{
    font-style: normal;
    font-size: 1.3125rem;
    font-family:'Fira sans', 'sans-serif';
    font-weight: 550;
    padding-left: 10%;
    padding-top: 3%;
    padding-right: 10%;


}
.description-loRa-edge{
    width: 70%;
    text-align: center;
    margin: 0 auto;
    margin-top: 3.5%;
    font-family:'Fira sans', 'sans-serif';
    font-size: 1.3125rem;
    color: #333;
}
.description-sign-up{
    margin-top: 5%;
    color: #00aeef;
    font-weight: 500;
}
.sign-up-section2{
    background-color: #00aeef;
    color: #ffffff;
    border-style: none;
    padding: 0.4375em;
   /* padding-left: 10%;*/
    margin-top: 10%;
    text-decoration: none;
   /* padding-right: 10%;*/
    cursor: pointer;
    font-family:'Fira sans', 'sans-serif';
    border: 1px solid #00aeef;
    font-size:1.3125rem;
    width: max-content;
    opacity: 100;
    transition: opacity .15s ease-in-out;
}
.sign-up-section2:hover{
    color: #fff;
    border: 1px solid #00aeef;
    transition: opacity .15s ease-in-out;
    opacity: .8;

}
.description-test-lora{
    border: 2px solid #00aeef;
    border-radius: 3px;
    padding: 2%;
    width: 90%;
    font-size: 1.3125rem;
    margin-top: 4%;
    color:#333
}
.Section3TextLandingPage{
    margin: 0 auto;
    width: 60%;
    text-align: center;
    font-family: 'Fira sans', 'sans-serif';
    font-size: 2.222vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.Section3TextLandingPage h3{
    margin: 0;
    margin: 1%;
    font-weight: 500;
    width: 50%;
    color: #00aeef;
    font-weight: 500;
    font-size: 2.25rem;
}
.Section3TextLandingPage p{
    margin: 0;
    margin-bottom: 1%;
    margin-top: 2%;
    font-size: 1.3125rem;
    color: #333;
    font-weight: 400;
}
.steps-tracking{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 61%;
    margin: 0 auto;
    position: relative;
    margin-top: 2%;
    height: 100%;


}
.steps {
    width:  22.5%;
    color: #333;
    background: #E9F4FC;
    text-align: center;
    font-size:1.3125rem;
    height: 30.5%;
    padding-bottom: 7%;
    padding-top: 7%;
    padding-left: 3%;
    padding-right: 3%;
    font-weight: bold;
}
.steps a{
    color:#00aeef;
    text-decoration:underline;
    cursor:pointer;
}
.circle-steps1{
  height: 4vw;
  width: 4vw;
  background: #00aeef;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 13.5%;
  font-weight: 500;
  font-size: 3.71vh;
  top: -2vw;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
}
.circle-steps2{
    height: 4vw;
    width: 4vw;
    font-weight: 500;
    background: #00aeef;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    position: absolute;
    left: 46.5%;
    font-size: 3.71vh;
    top: -2vw;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .circle-steps3{
    height: 4vw;
    width: 4vw;
    background: #00aeef;
    border-radius: 50%;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    position: absolute;
    left: 79.5%;
    font-size: 3.71vh;
    top: -2vw;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .section3-text{
    margin: 0 auto;
    width: 80%;
    text-align: center;
  }
  .semtech-partners-text{
      color: #333;
      font-family:'Fira sans', 'sans-serif';
      font-weight: 400;
      font-size: 1.3125rem;
  }
  .host-tracking-text{
      color: #00aeef;
      font-size: 1.3rem;
      font-weight: 500;
      font-family:'Fira sans', 'sans-serif';

  }
  .go-loracloud-text{
      color: #333;
      font-family:'Fira sans', 'sans-serif';
  }
  .go-loracloud-text a{
      color: #00aeef;
      text-decoration: none;
      font-weight: 500;
  }
  .scale-solution{
      color:#333;
      font-family:'Fira sans', 'sans-serif';
      font-weight: 400;
  }
  .section4{

      width: 75%;
      margin: 0 auto;

      font-size: 1.3125rem;
      font-family:'Fira sans', 'sans-serif';
  }
  .section4Background{
    background-color:#E9F4FC ;
    padding-bottom: 6%;
    padding-top: 6%;
}
  .section4Text{
    margin-bottom: 1%;
    text-align: center;
    margin-bottom: 0;
  }
  .section4Text > h3{
    color: #00aeef;
    font-size: 2.25rem;
    margin-top: 0;
    font-weight: 500;
  }
  .section4Text > p{
    color: #333;
    font-size:1.3125rem;
    font-weight: 400;
    margin-left: 5%;
    max-width: 90%;
  }
  .battery-description{
    display: flex;
    flex-direction: row;
    margin-bottom: 8%;
    position: relative;
    align-items: center;
  }
  .battery-text{
      border: 2px solid #00aeef;
      width: 51%;
      border-radius: 10px;
      padding-left: 2.5%;
      padding-right: 2.5%;
      color: #333;
  }
  .battery-icon{
      vertical-align: middle;
      align-items: center;
      text-align: center;
      width: 20%;
      margin-left: 6%;
      font-weight: 500;
      font-size: 1.7rem;
  }
  .battery-icon img{
    width: 28%;
  }
  .battery-icon p{
      margin-top: 0%;
      color: #00aeef;
  }
  .connection-description{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 8%;
    position: relative;
    align-items: center;
}
.connection-text{
    border: 2px solid #00aeef;
    width: 51%;
    border-radius: 10px;
    padding-left: 2.5%;
    padding-right: 2.5%;

    color: #333;
}
.connection-icon{
    font-size: 1.7rem;
    vertical-align: middle;
    align-items: center;
    text-align: center;
    width: 20%;
    margin-right: 6%;
    font-weight: 500;
    font-size: 1.7rem;
}
.connection-icon img{
  width: 28%;
}
.connection-icon p{
    margin-top: 0%;
    color: #00aeef;
}

.blue-arrow1 {
    width: 9%;
    height: 8%;
    position: absolute;
    right: 34.8%;
    bottom: 29%;
    transform: rotate(180deg);

}

.blue-arrow2 {
    width: 9%;
    height: 8%;
    position: absolute;
    right: 56.2%;
    bottom: 29%;
}


.blue-arrow3 {
    width: 6%;
    height: 8%;
    position: absolute;
    right: 37.8%;
    bottom: 29%;
    transform: rotate(180deg);
}

.ecosystem-description{
    display: flex;
    flex-direction: row;
    color: #333;
    position: relative;
    align-items: center;
}
.ecosystem-text{
    border: 2px solid #00aeef;
    width: 51%;
    border-radius: 10px;
    padding-left: 2.5%;
    padding-right: 2.5%;
    color: #333;
    text-align: left;
}

.ecosystem-icon{
    vertical-align: middle;
    align-items: center;
    text-align: center;
    width: 20%;
    margin-left: 6%;
    position: relative;
    font-weight: 500;
    font-size: 1.7rem;
}
.ecosystem-icon img{
  width: 28%;
}
.ecosystem-icon p{
    margin-top: 0%;
    color: #00aeef;
}
.section5{

    width: 80%;

    display: flex;
    margin: 0 auto;
    margin-bottom: 2%;
    margin-top: 0%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.section5Background{
    background-color: #FFFFFF;
    margin-top: 0vh;
    padding-top: 0;


    padding-bottom: 1vh;
}
.section5-title{
    color: #00aeef;
    margin-bottom: 1%;
    text-align: center;
    font-weight: 500;
    margin-top: 7.2%;
    font-size: 2.25rem;
    font-family:'Fira sans', 'sans-serif';
  }

.section6{
    width: 80%;
    display: flex;
    margin: 0 auto;
    margin-bottom: 2%;
    margin-top: 0%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.section6Background{
    background-color: #E9F4FC;
    padding-top: 6%;
    padding-bottom: 2%;
}

.section6-title{
    width: 100%;
    text-align: center;
    font-size: 2.25rem;
    font-family: 'Fira sans', 'sans-serif';
    color: rgb(51, 51, 51);
    margin-block-end: 0;
    font-weight: 500;
    margin-block-start: 0;
}
.rightSide{
    width: 48%;
    margin: 4%;
    text-align: left;
    font-family:'Fira sans', 'sans-serif';
}
.leftSide{
    width: 48%;
    margin: 2%;

}
.leftSide > img{
    width: 100%;
}
.testimonialTitle{
    color: #00aeef;
    font-weight: 450;
    font-size: 1.6875rem;
    margin-bottom: 2%;
}
.testimonialGeneral{
    border: 2px solid #00aeef;
    font-family: 'Fira sans', 'sans-serif';
    background-color: #ffffff;
    border-radius: 5px;
}
.testimonialGeneral > p > h4{
    color: #333;
    margin: 0;
}
  .reviews{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
  }
  .review-format{
      width: 44%;
      border: 2px solid #00aeef;
      padding-right: 2%;
      padding-left: 2%;
      background-color: #FFFFFF;
      position: relative;
      border-radius: 5px;
      min-height: 35vh;
      height: 100%;
      max-height: 46.5vh;
      overflow: hidden;
  }

  .review-format1{
    width: 100%;
    position: relative;
    overflow: hidden;

}
.testimonials{
    display: flex;
    flex-direction: row;
}

.testimonial-text{
    padding-right: 6%;
    padding-left: 6%;
    font-size: 16px;
    padding-bottom: 4%;
}
.testimonial-text > {
    font-weight: 400 ;
}

  .double-quotes-style{
      position: absolute;
      width: 43% !important;
      left: -3%;
  }

  .double-quotes-testimonial-style{
    position: absolute;
    width: 44% !important;
    left: -10%;
  }

  .logo-format{
    display: flex;
    position: relative;
    left: 44% !important;
    text-align: right;
    /* top: -20%; */
    /* height: 9vh; */
    width: 55%;
    margin-top: 1%;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: stretch;
  }
  .logo-format img{
    width: 50% ;
    margin-top: 0% ;
    margin-bottom: 0%;
  }
  .logo-format-testimonial{
    position: relative;
    left: 38% !important;
    width: 18%;
    bottom: 12%;
  }
  .logo-format-testimonial img{
    width: 20% ;
  }
  .text-format > p {
    margin-top:2% ;
    text-align: left;
    min-height: 30vh;
    }
  .text-format{
      color: #333;
      margin-bottom: 10%;
      font-size: 1.3125rem;
      font-family:'Fira sans', 'sans-serif';
  }
  .leftRightPanelsLP {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.leftImageHome{
    height: 20vh;
}
.leftImageHome > img{
    width: 4.4vw;
    margin-top: 5vh;
}
.rightTextHome{
    margin-left: 2%;
    max-width: 75%;
    width: 75%;
    font-size: 90%;
}

.rightTextHome > p > b{

    font-size: 90%;
    font-weight: 500;
}
.rightTextHome > h3{
    color: #00aeef;
    font-family:'Fira sans', 'sans-serif';
    font-weight: 450;
    font-size: 1.6875rem;
    margin-bottom: 1%;
}

.rightTextHome > p{
    font-size: 1.3125rem;
    color: #333;
    margin-top: 0%;
    font-family:'Fira sans', 'sans-serif';
}


.RightImageSection{
    text-align: center;
    padding-left: 3%;
    padding-right: 10%;
    width: 100%;
}
.RightImageSection > h3{
    text-align: center;
    font-weight: 450;
    margin-top: 0;

    font-size: 3.33vh;
    color:#00AEEF;
}
.RightImageSection >p {
    text-align: center;
    font-size: 2.22vh;
    color: #333;

}
.RightImageSection >p >b{
    text-align: center;
    font-size: 2.22vh;
    color: #333;
    font-weight: 550;

}
.RightImageSection3Columns{
    column-count:2;
    text-align: left;

    line-height: 3vh;
    column-gap:1%;
    font-weight:500;
    color: #333;
    margin-top: 4%;
}
.RightImageSection3Columns >ul{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}


.RightImageSection3Columns > ul > li {
  margin-bottom: 4%;
  font-size: 2.222vh;
  text-decoration: none;
}

.RightImageSection3Columns > ul > li:hover {
    color: #00aeef;
  }


  .RightImageSection3Columns > ul > li > a:hover{
    color: #00aeef;
  }
  .RightImageSection3Columns > ul > li > a{
    color: #333;
    text-decoration: none;

  }
.RightImageSection3ColumnsGrid

{
    display: flex;
   justify-content: center;
}


.carouselcustomers{
    max-width: 80vw;
}

.carouselcustomers .control-next.control-arrow:hover:before {
    border-right: solid .3vw #00aeef !important;
    border-bottom: solid .3vw #00aeef !important;
}
.carouselcustomers .control-prev.control-arrow:hover:before  {
    border-right: solid .3vw #00aeef !important;
    border-bottom: solid .3vw #00aeef !important;
}


.carouselcustomers .control-next.control-arrow:before {
    content: '';
    transition: border-bottom .2s;
    transition:border-right .2s;
    border-right: solid .2vw #00aeef !important;
    border-radius: 1px;
    border-bottom: solid .2vw #00aeef !important;
    border-left: solid 0vw #00aeef !important;
    border-top: solid 0vw #00aeef !important;
    display: block !important;
    opacity: 1 !important;
    height: 2.5vh;
    width: 2.5vh;

    padding-left:0% !important;
    right: 1.1%;
    position: relative;
    padding-right:0% !important;
    padding-top:0 !important;
    padding-bottom:0 !important;
    display: inline-block;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.nextArrow:hover:before {
    border-right: solid .3vw #00aeef !important;
    border-bottom: solid .3vw #00aeef !important;
}
.prevArrow:hover::before  {
    border-right: solid .3vw #00aeef !important;
    border-bottom: solid .3vw #00aeef !important;
}

.nextArrow:before {
    content: '';
    transition: border-bottom .2s;
    transition:border-right .2s;
    border-right: solid .2vw #00aeef !important;
    border-radius: 1px;
    border-bottom: solid .2vw #00aeef !important;
    border-left: solid 0vw #00aeef !important;
    border-top: solid 0vw #00aeef !important;
    display: block !important;
    opacity: 1 !important;
    height: 2.5vh;
    width: 2.5vh;

    padding-left:0% !important;
    right: 1.1%;
    position: relative;
    padding-right:0% !important;
    padding-top:0 !important;
    padding-bottom:0 !important;
    display: inline-block;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin: 0 5px;
}

.prevArrow::before{
    content: '';
    transition: border-bottom .2s;
    transition:border-right .2s;
    border-right: solid .2vw #00aeef !important;
    border-radius: 1px;
    border-bottom: solid .2vw #00aeef !important;
    border-left: solid 0vw #00aeef !important;
    border-top: solid 0vw #00aeef !important;
    display: block !important;
    opacity: 1 !important;
    height: 2.5vh;
    width: 2.5vh;
    padding-left:2% !important;
    padding-right:2% !important;
    display: inline-block;
    padding-bottom: 0vh !important;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin: 0 5px;

}

.prevArrow{
    background-color: #fff !important;
    top: 40% !important;
    display: block !important;
    padding-right: 0 !important;
    padding-left: 0.7% !important;
    position: absolute;
    border-radius: 5px;
    padding-top: 0 !important;
    left: .5vw !important;
    padding-bottom: 0 !important;
    box-shadow:#00000029 0px 3px 6px;
    opacity: 1 !important;
    width: 2.5vw;
    height: 7vh;
    z-index:2 ;
    border: none;
}

.nextArrow {
   background-color: #fff !important;
   top:40% !important;
   padding-right: 4% !important;
   display: block !important;
   padding-left: 0 !important;
   padding-top: 0 !important;
   right: .5vw !important;
   position: absolute;
   padding-bottom: 0 !important;
   box-shadow:#00000029 0px 3px 6px;
   opacity: 1 !important;
   height: 7vh;
   width: 2.5vw;
   border-radius: 5px;
   border: none;

}



.carouselcustomers .control-prev.control-arrow:before {
    content: '';
    transition: border-bottom .2s;
    transition:border-right .2s;
    border-right: solid .2vw #00aeef !important;
    border-radius: 1px;
    border-bottom: solid .2vw #00aeef !important;
    border-left: solid 0vw #00aeef !important;
    border-top: solid 0vw #00aeef !important;
    display: block !important;
    opacity: 1 !important;
    height: 2.5vh;
    width: 2.5vh;
    padding-left:2% !important;
    padding-right:2% !important;
    display: inline-block;
    padding-bottom: 0vh !important;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}





.carouselcustomers .control-next.control-arrow {
   background-color: #fff !important;
   top:40% !important;
   padding-right: 4% !important;
   display: block !important;
   padding-left: 0 !important;
   padding-top: 0 !important;
   right: .5vw !important;

   padding-bottom: 0 !important;
   box-shadow:#00000029 0px 3px 6px;
   opacity: 1 !important;
   height: 7vh;
   width: 2.5vw;
    border-radius: 5px;

}

.carouselcustomers .control-prev.control-arrow {
    background-color: #fff !important;
    top:40% !important;
    display: block !important;
    padding-right: 0 !important;
    padding-left: 0.7% !important;

    border-radius: 5px;
    padding-top: 0 !important;
    left: .5vw !important;
    padding-bottom: 0 !important;
    box-shadow:#00000029 0px 3px 6px;
    opacity: 1 !important;
    width: 2.5vw;
    height: 7vh;

}


.sign-up-sectionleft{


background-color: #00aeef;
color: #ffffff;
border-style: none;
padding: 0.4375em;
padding-left: 3%;
margin-top: 10%;
padding-right: 3%;
cursor: pointer;
font-family:'Fira sans', 'sans-serif';
border: 1px solid #00aeef;
transition: .4s background-color;
font-size:2.222vh;

}



.sign-up-sectionleft:hover{
color: #00aeef;
background-color: #ffffff;
border: 1px solid #00aeef;
}
.SectionLeftAlignmentCenter{
    display: flex;
    margin-top: 6%;
    margin-bottom: 1%;
}

.leftImageSection{
    font-family:'Fira sans', 'sans-serif' !important;
    margin-bottom: 5%;

    width: 100vw;
    display: flex;
    align-items: center;
}
.SectionLeftAlignmentCenter > img{
    width: 30vw;
    height: 50vh;
}
.specialCalchipImageLogo{
    height:4.5vw;
    margin-top:-12% !important;
}
