.TitleLDUsecases{
    color: #00AEEF;
    font-family: "Fira sans" !important;
    font-size: 3.84vh;
    font-weight: 450;
    width: 62%;
    margin: 4.8% auto;
   
}
.TitleLDUsecases h3{
    font-weight: 500;
}
.useCaseMainLP{
    padding-left:10.42%;
    padding-right:10.42%;
    font-family: "Fira sans" !important;
}
.TextLDUsecases{
    color: #333;
    font-size: 2.222vh;   
    font-family: "Fira sans" !important;
}
.TextLDUsecases >b >i {
    color: #333;
    font-size: 2.222vh;   
    font-weight: 500;
    font-family: "Fira sans" !important;
}
.TwoColumnsDivUseCasesLP{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 5%;
    text-align: center;

}
.LeftColumnDivUseCasesLP{
    min-height:10vh;
    width: 45%;
  
}

.RightColumnDivUseCasesLP{
    min-height:10vh;
    width: 45%;
    
}
.LeftColumnDivUseCasesLP > center > p {
text-align: center;
font-size: 1.3125rem;
min-height: 7vh;
max-width: 90%;
}
.RightColumnDivUseCasesLP > center > p {
font-size: 1.3125rem;
min-height: 8vh;
max-width: 90%;
}
.LeftColumnDivUseCasesLP > .imgleftalignusecasesLP > img{
    width: 95%;
    
}
.RightColumnDivUseCasesLP >  .imgleftalignusecasesLP > img {
    width: 95%;
}
.LeftColumnDivUseCasesLP > h3{
  text-align: left;
  font-size: 2.25rem;
  color:#00AEEF;
  font-weight: 450;
}
.RightColumnDivUseCasesLP > h3 {
  text-align: left;
  font-size: 2.25rem;
  color:#00AEEF;
  font-weight: 450;
}


.LeftColumnDivUseCasesLP > h4{
  text-align: left;
  font-size: 1.88rem;
  color:#00AEEF;
  font-weight: 450;
  margin-bottom: 0;
  }
  .RightColumnDivUseCasesLP > h4 {
  text-align: left;
  margin-bottom: 0;
  font-size: 1.88rem;
  color:#00AEEF;
  font-weight: 450;
  }


.BlueButtonUseCasesLP{
    background-color: #00aeef;
    color: #ffffff;
    border-style: none;
    padding: 0.4375em;
    text-decoration: none;
    padding-left: 3%;
  
    padding-right: 3%;
    cursor: pointer;
    font-family:'Fira sans', 'sans-serif';
    border: 1px solid #00aeef;
    transition: .4s background-color;
    font-size:1.3125rem;
}
.BlueButtonUseCasesLP:hover{
    color: #00aeef;
    background-color: #ffffff;
    border: 1px solid #00aeef;
}
.TextUsecasesLPLeft{
    text-align: left;
    color: #333;
    font-size: 1.3125rem;
    margin-top: 0;
    width: 90%;
}
.imgleftalignusecasesLP{
    text-align: left !important;
    width: 95%;
}

