.rotateimg270{
    -webkit-transform:rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .imgContainerLandingPageMain{
    width:99vw;
    display: grid;
    align-items: end;
    z-index: -1;
    justify-content: center;
    font-family: 'Fira Sans';
   
  
  }
  .img-home{
    
        position: relative;
        object-fit: fill;
        min-width: 100vw;
        max-width: 100vw;
        height: 50vh;
  }
.text-welcome-semtech{
    font-family: "Fira sans" !important;
    position: relative;
    color: #ffffff;
    background-color: #00aeefe6;
    padding: 1vw;
    margin-top: -60vh;
    min-height: 23.0vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  
   
}
.whatsNewBox{
    border: solid 1px #00AEEF;
    margin-top: -100%;
    min-height:18vh;
    min-width: 30vw;
    margin-bottom: 1.2vh !important;
    position: relative;
    background-color: #FFF;
    display: flex;
    padding-bottom: 1.5%;
    padding-left: 1.5%;
    padding-top: 1%;
    padding-right: 1.5%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
}
.message-welcome{
   
    padding-left: 15%;
    padding-right: 10%;
    width: 70%;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 2rem;
   
   
}
.message-welcome > p {
    font-size:1.3125rem;
    margin-block-start: 0%;
}
.message-welcome > h3 {
 font-size: 2.25rem;
 margin-bottom: 0%;
 font-weight: 500;
}


.message-welcome > p  >b  >i{
    font-weight: 500;
    font-size:2.78vh;
}


@keyframes fadeIn {
    0%{ opacity:0 }
    100%{ opacity: 1 }
}

.whatsNewBox > p{
   font-size: 1.3125rem;
   border-left: solid 3px #00aeef;
   padding-left: 1%;
   margin-right: -1%;
   color:#00aeef;
   font-weight: 500;
}
.whatsNewBox > h1{
    font-size: 1.3125rem;
    border-left: solid 3px #00aeef;
    padding-left: 1%;
    margin-bottom: 1px;
    margin-right: -1%;
    color:#00aeef;
    font-weight: 500;
 }

.whatsNewBox > center > a{
    padding-left: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 3%;
    font-size: 1.3125rem;
    margin-top: 1%;
    background-color: #00aeef;
    color: #fff;
    border:solid 1px #00aeef;
    text-decoration: none;
    width: 3.7%;
    height: 40px;
    opacity: 100;
    transition: opacity .15s ease-in-out;
 }
 .whatsNewBox > center{
    margin-top: 5%;
 }
 
.whatsNewBox > center > a:hover{
    font-family: "Fira sans";
    color: #fff;
    border: 1px solid #00aeef;
    transition: opacity .15s ease-in-out;
    opacity: .8;
    
 }
 .whatsNewBox > h3{
    margin: 0;
    font-size: 1.875rem;
    font-family: 'Fira Sans';
    font-weight: 500;

 }
 
.MessagesContainer{
    display: flex;
    align-items: flex-end;
}
