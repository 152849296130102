.whatsNewBody{
    margin-top:3%;
    margin-bottom:3%;
    border-radius: 1%;

    font-family: "Fira sans" !important;
    padding-left:10.42%;
    overflow:hidden;
    margin-right:10.42%;
    color:#333;



    overflow:hidden;
    max-width: 1920px;
    font-size: 1.3125rem;
}

.whatsNewContent{
    padding: 4%;
    padding-top: 1%;
    overflow-y: hidden;
    text-overflow: ellipsis;
    text-rendering: optimizeSpeed;
}
.whatsNewContentTitle{

    font-family: "Fira sans";

}
.whatsNewContentTitle > h1{
    display: flex;
    font-size: 5.33vh;
    font-family: "Fira sans";
    font-weight: 500;
}
.WhatsNewTittle{
    display: flex;
    font-family: "Fira sans";
    font-size: 2.5rem;
    font-weight: normal;
    color:#333;

}
.whatsNewContentDocRendering >h1{
    margin: 0px;
    font-family: "Fira sans";
    font-size: 2.25rem;
    color: #333;
    font-weight: 550;
}
.whatsNewContentDocRendering > h2 {
    color:#00aeef;
    font-family: "Fira sans";
    margin: 0px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 1.3125rem;
    font-weight: normal;
}
.whatsNewContentDocRendering > p {
    color:#333;
    font-family: "Fira sans";
    margin: 0px;
    margin-top: 2%;
    margin-bottom: 2%;
    font-size:1.3125rem;
    font-weight: normal;

}
.whatsNewContentDocRendering > p >a {
    color:#00AEEF;
}
.LoadingGif{
    height: 20vh;
}
.whatsNewHeaderLP{
    display: flex;
    justify-content: flex-end;

}
.whatsNewContentFilter{
    text-align: center;
    margin-bottom: 1%;

}
.whatsNewContentFilter > p{
    margin-bottom: 1%;
    font-size: 1.3125rem;
    color: #00aeef;
    font-weight: 400;
}

.whatsNewContentFilter > select{
    margin-bottom: 1%;
    border: solid 1px #00aeef;
    border-radius: 5px;
    color: #333;
    font-size: 1.3125rem;

    text-align: center;
    font-weight:lighter;

}



.whatsNewContentFilter > select > option {
    margin-bottom: 1%;
    font-size: 1.7vh;
    color: #00aeef;
    width: 110%;
    font-weight: 400;
    background-color:#FFF;

}

.optionWhatsNew:checked {
    box-shadow: 0 0 10px 100px #EBEBEB inset;
    margin-bottom: 1%;

    font-size:1.7vh;
    color: #333;
    font-weight: 400;
    background-color:#EBEBEB;

}
.optionWhatsNew{
    color: #00aeef;
    margin:1% !important;
    padding-left:10%;
    cursor: pointer;
    border-radius: 4px;
}

.optionWhatsNew:hover{
 background-color: #00aeef;
 border-radius: 4px;
 color:#fff;
}
.whatsNewSelectRoot > fieldset {
    border: none !important;
}
.whatsNewSelectOutlined{
    min-width: 15vh !important;
    outline: solid 2px #00aeef !important;
    border-radius: 4px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
