.carousselProductsTrackersLP  > div > ul >li {
background-color:#C0DFEB !important;
height: 1.5vh !important;
width: 1.5vh !important;
opacity: 1 !important;
margin-top: 3% !important;
box-shadow: none !important;
}
.carousselProductsTrackersLP  > div > ul >li:hover {
    background-color:#a5dbf0 !important;
    height: 1.5vh !important;
    width: 1.5vh !important;
    opacity: 1 !important;
  
    box-shadow: none !important;
    }
.carousselProductsTrackersLP  > div > ul > .dot.selected {
    background-color:#00AEEF  !important;
    height: 1.5vh !important;
    width: 1.5vh !important;
    box-shadow: none;
}
.tableProductLP{
    border-collapse: collapse;
    
    margin-bottom: 3%;
    margin-top: 3%;
}
.tableProductLP >tr {

}
.tableProductLP > tbody > tr > td {
    margin:0;
   
    font-size: 1.3125rem;
    color: #333;
    border: solid 1px #00AEEF;
    padding:10px;
    
}
.carousselProductsTrackersLP{
    width: 80% !important;
}
.carousselProductsTrackersLP  > div > .control-dots{
    top: 95%;
}