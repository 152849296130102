.pointerIcon{
    position: absolute;
    top:90vh;
    height: 70vh;
    z-index: -1;
    left: 70vw;
}
.DocTittle{
    font-size: 1.2rem;
    color: #00AEEF;
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.DocumentRow{
width: 80vw;
display: flex;}
.squareBlue{
display: flex;
height:30vw;
width: 50vw;
background-color: #00AEEF;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
border-radius: 0 2% 2% 0;
}
.squareBlueIcon{
display: flex;
padding-left:10%;
align-items:flex-end;
}
.squareBlueText{ 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    overflow: hidden;
    padding-top: 2%;
    font-size: 1rem;
    padding-left: 3%;
    text-overflow: ellipsis;
 
    color:#fff;

}
.squareBorderBlue{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    width: 30vw;
    margin-top: 3%;
    border-radius: 0 2% 2% 0;
    max-height: 23vw;
    overflow: hidden;
    border: solid 1px #00AEEF;
    padding-left: 3%;
    padding-right: 3%;
    text-overflow: ellipsis;
    text-indent: 2%;
    padding-bottom: 3%;
    padding-top: 1%;
}

.DocumentSection2{
    display: flex;
}
.leftRow{ 
    height: 15vw; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    width: 4vw;
    background-color: #00AEEF; 
    border-radius: 20vw 20vw 20vw 20vw / 20vw 20vw 20vw 20vw;
    position: absolute;
    margin-left:5vw;
    margin-top:10vh;

}

.tittleSection2{color:#00AEEF;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding-left: 15%;
    padding-top: 5%;
    padding-bottom: 3%;
    font-size: 1.2rem;
    font-weight: 600;
}
.textSection2{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    overflow: hidden;
    max-height: 10vw;
    text-overflow: ellipsis;
    margin-left: 15%;
}

.DocumentSection3{display: flex;
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.section3Text{
    padding-top: 8%;
}

.downloadText{
  color: #00AEEF;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: -2%;

}