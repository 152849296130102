.footer_landing_container_mobile{
    display: flow-root;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}
.footer_date >a{
    font-family: 'Fira sans';
    color: #333;
    text-decoration: none;
    font-size: 12px;
    margin-right:1%;
    margin-left:1%;
    align-items: center;
    margin-top: 2%;
    padding-right: 2%;
}
.footer-links_landing_mobile  , .footer-links_landing_mobile_last{
margin-top: 2%;
}

.footer-links_landing_mobile  > a{
    font-family: 'Fira sans';
    color: #333;
    text-decoration: none;
    font-size: 12px;
  border-right: solid 1px #333;
    border-left: solid 1px #333;
    margin-right:1%;
    margin-left:1%;
    align-items: center;
    padding-right: 2%;
    padding-left: 2%;
}
.footer-links_landing_mobile_last > a{
    font-family: 'Fira sans';
    color: #333;
    text-decoration: none;
    font-size: 12px;
    border-right: solid 1px #333;
    margin-right:1%;
    margin-left:1%;
    align-items: center;
    padding-right: 2%;
    padding-left: 2%;
}
.footer-links_landing_mobile   > a:hover{
    text-decoration: underline;
}
.blue_links_landing_mobile > a{
    font-family: 'Fira sans';
    color: #108AC1;
    text-decoration: none;
    font-size: 12px;
    margin-right:1%;
    margin-left:1%;
    padding-right: .5%;

}
.blue_links_landing_mobile > a:hover{
    text-decoration: underline;
}
