
.tableProudctLPMobile{

    min-width: 70vw;
    max-width: 70vw;
    border-collapse: collapse;
    margin-bottom: 15%;
    margin-top: 15%;
}
.tableProudctLPMobile >tr {

}
.tableProudctLPMobile > tbody > tr > td {
    margin:0;
    font-size: 3.5vw;
    color: #333;
    border: solid 1px #00AEEF;
    padding:10px;
    
}