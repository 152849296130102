.Section3TextLandingPageMobile > center > h3 {
    margin: 0;
    margin: 1%;
    font-weight: 500 !important;
    width: 100%;
    font-family: 'Fira Sans';
    color: #00aeef;
    font-weight: 500;
    font-size: 5vw;
}
.Section3TextLandingPageMobile > center > p {
color: #333;
font-weight: 500 !important;
font-family: 'Fira Sans';
font-size: 4vw;
margin: 3%;
}

.stepsTrackingMobile > span{

}
.stepsTrackingMobile{
   
    display: flex;
    width: 90%;
    margin: 0 auto;
    position: relative;
    margin-top:10%;
    height: 100%;
    align-items: center;
    flex-direction: column;
}
.circleStepsMobile{
  height: 12vw;
  width: 12vw;
  background: #00aeef;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  position: relative;
  left: 30vw;
  font-weight: 500;
  font-size: 2.5vh;
  top: -7vw;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 1%;
  }
  .stepsMobile{
    width:  80%;
    background: #e9f4fc;
    text-align: center;
    font-size:4vw;
    border-radius: 3.5%;
    height: 22.5%;
    padding-bottom: 6%;
  
    padding-left: 3%;
    padding-right: 3%;
    font-weight: bold;
    margin-bottom: 15%;
  }
  .subtitleMobileWhatsNewMobile{
      font-family: 'Fira Sans';
      color: #333;
      font-size: 3.5vw;
      font-weight: 450;
  }
  
.sign-up-section2Mobile{
    margin-top: 10%;
    display: flex;
    background-color: #00aeef;
    color: #ffffff;
    border-style: none;
    padding: 0.4375em;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 5%;
    padding-right: 5%;
    cursor: pointer;
    font-family:'Fira sans', 'sans-serif';
    border: 1px solid #00aeef;
    transition: .4s background-color;
    font-size:3vw;
    width: max-content;
}
.sign-up-section2Mobile:hover{
    color: #00aeef;
    background-color: #ffffff;
    border: 1px solid #00aeef;
}
.subtitleMobileWhatsNewMobile > center > p {
color: #333;
}

.section4TextMobile{
    margin-bottom: 1%;
    text-align: center;
    margin-bottom: 0;
  }
  .section4TextMobile > h3{
    color: #00aeef;
    font-size: 3.33vh;
    margin-top: 0;
    font-weight: 500;
  }
  .section4TextMobile > p{
    color: #333;
    font-size:2.222vh;
    font-weight: 400;
    margin-left: 5%;
    max-width: 90%;
  }

  .section4BackgroundMobile{
    background-color:#E9F4FC ;
    padding-bottom: 6%;
    padding-top: 6%;
}
.section4Mobile{
    width: 90%;
    margin: 0 auto;
    margin: 5%;
    font-size: 1.3125rem;
    font-family:'Fira sans', 'sans-serif';
}

.section4TextMobile{
  margin-bottom: 1%;
  text-align: center;
  margin-bottom: 0;
}
.section4TextMobile > h3{
  color: #00aeef;
  font-size: 5vw;
  margin-top: 0;
  font-weight: 500;
}
.section4TextMobile > p{
  color: #333;
  font-size:4vw;
  font-weight: 500;
  margin-left: 5%;
  max-width: 90%;
}

.leftImageHomeMobile{
    height: 20vh;
    margin-right: 2%;
}
.leftImageHomeMobile > img{
    width: 15vw;
    margin-top: 5vh;
}

.rightTextHomeMobile{
    margin-left: 5%;
    max-width: 70%;
    width: 70%;
    font-size: 2.222vh;
}

.rightTextHomeMobile > p > b{
    font-size: 3.5vw;
    font-weight: 650;
}
.rightTextHomeMobile > h3{
    color: #00aeef;
    font-family:'Fira sans', 'sans-serif';
    font-weight: 450;
    font-size: 2.5vh;
    margin-bottom: 1%;
}

.rightTextHomeMobile > p{
    font-size: 3.5vw;
    font-weight: 500;
    color: #333;
    margin-top: 5%;
   
    font-family:'Fira sans', 'sans-serif';
}
.Section3TextLandingPageMobile{
    margin-top:0%;
}


.Section3TextLandingPageMobile > center > button{
    width:65%;
    font-size: 3vw;
    font-weight: 400;
    font-family: 'Fira Sans';
}

.section5-titleMobile{
    margin-top: 30%;
   
    color: #00aeef;
    margin-bottom: 10%;
    text-align: center;
    font-weight: 500;
    margin-top: 7.2%;
    font-size:200%;
    font-family:'Fira sans', 'sans-serif';
  }
  
  .review-formatMobile{
    width: 92%;
    border: 2px solid #00aeef;
    padding-right: 2%;
    padding-left: 2%;
    background-color: #FFFFFF;
    position: relative;
    border-radius: 5px;
    height: auto;
  
    overflow: hidden;
   
}

.logo-formatMobile{
    display: flex;
    position: relative;
    left:43% !important;
    height: 5vh;
    width: 100%;
    margin-top: 13%;
}
/*.logo-formatMobile img{
  width: 100%;

  margin-top: 0%;
  margin-bottom: 0%;
}*/
.text-formatMobile > p {
    overflow-y:scroll;
    margin-left: 1%;
    margin-right: 1%;
    font-size: 150%;
    margin-top:5% ;
    text-align:left;
    word-break: all;
    }
  .text-formatMobile{
      color: #333;
      margin-bottom: 10%;
      font-size:90%;
  }
  .testimonials-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family:'Fira sans', 'sans-serif';
  }

  .section6-title-mobile{
    width: 100%;
    text-align: center;
    font-size: 3.17667rem;
    font-family: 'Fira sans', 'sans-serif';
    color: rgb(51, 51, 51);
    margin-block-end: 0;
    font-weight: 500;
    margin-block-start: 0;
}
  .rightSideMobile{
    width: 75%;
    margin: 4%;
    text-align: left;
    font-family: 'Fira sans', 'sans-serif';
  }
  .leftSideMobile{
    width: 97%;
    margin-bottom: 5%;
  }
  .testimonialTitleMobile{
    color: #00aeef;
    font-weight: 450;
    font-size: 2.3475rem;
    margin-bottom: 4%;
    margin-top: 6%;
}
.testimonialGeneralMobile{
  border: 2px solid #00aeef;
  background-color: #ffffff;
  border-radius: 5px;
}
.review-format-mobile{
  width: 100%;
  position: relative;
  overflow: hidden;
}
.testimonial-text-mobile{
  color: rgb(51, 51, 51);
  padding-right: 6%;
  padding-left: 6%;
  padding-bottom: 4%;
  font-size: 177.1%
}

