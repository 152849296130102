.mainHeaderPages{
    width: 100vw;
    height: 5vh;
    text-align: center;
    box-shadow: 0px 3px 6px #00000029;
    align-items: center;
    display: flex;
    font-family: 'Fira Sans';
    font-weight: 500;
    font-size: 140%;
    justify-content: center;
}
.TitleLDUsecasesMoblie{
    color: #333;
    font-family: "Fira sans" !important;
    font-size: 5vw;
    font-weight: 450;
    width: 85%;
    margin: 4.8% auto;
}
.TitleLDUsecasesMoblie h3{
    font-weight: 500;
}

.imgUseCasesMobile{
    max-width: 100vw;
}
.imgUseCasesMobile >img {
    width: 100%;
}
.useCaseMainLP > h3{
    color: #00AEEF;
    font-family: "Fira sans" !important;
    font-size: 5vw;
    font-weight: 450;
}


.useCaseMainLP > h4{
    color: #00AEEF;
    font-family: "Fira sans" !important;
    font-size: 4vw;
    margin-bottom: 0;
    font-weight: 450;
}


.useCaseMainLP > p{
    color: #333;
    font-family: "Fira sans" !important;
    font-size: 3vw;
    font-weight: 450;
}