.support-email{
    height: 100%;
    width: 100%;
}
.content{
    height: 70%;
    position: relative;
}
.support-email-text{
    color: #00aeef;
    font-weight:500;
    font-size: 1.3em;
    font-family: 'Fira Sans','sans-serif';
    font-style:normal;
    background-color: #ffffff;
    padding: 0.5rem;
    padding-left: 12rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    position: absolute;
    top: 0%;
    flex-wrap: nowrap;
    flex-direction: row;
    display: inline-block;
    min-width: 5em ;
}
.support-email-text img{
    width: 3em;
    margin-right: 0.5em;
}
.email-support-title{
    position: absolute;
    background-color: #ffffff;
    top: 20vh;
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    z-index: 2;
    flex-direction: row;

}
.email-support-title > p > hr{
    border: 1px solid #00aeef;
    background-color: #00aeef;

}
.email-support-title img{
    width: 2.2em;
    margin-right: 0.5em;
}
.email-support-title p{
    color: #00aeef;
    font-weight: 500;
    font-size: 1.1rem;
    font-family: 'Fira Sans','sans-serif';
    opacity: 1;
    letter-spacing: 0px;
}
.email-support-content{
    height: 100%;
}
.email-support-content img{
    width: 100%;
    height: 100%;
}
.email-support-box{
    background-color: #ffffff;
    position: relative;
    bottom: 69%;
    left: 40%;
    width: 44%;
    display: flex;
    flex-direction: column;
    padding: 3%;
    border-bottom-left-radius: 3.0625em;
    border-top-left-radius: 3.0625em;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-right: 13%;
    padding-bottom: 1.3%;
}
.email-support-box p{
    font-family: 'Fira Sans','sans-serif';
    color: #333;

}

.support-button{
    background-color: #00aeef;
    color: #ffffff;
    border: none;
    border-radius: 1.56em;
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    font-family: 'Fira Sans','sans-serif';
    font-style: normal;
}

.contact {
    font-size: 0.8rem;
}

.bread-crum{
    color: #ffffff;
}


.bread-actual{
    color: #00aeef;

}
