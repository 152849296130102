.HeaderMobileMenu{
  background-color: #333;
  width: 100%;
}
.HeaderMobileMenu >div >div {
  left: 0;
  opacity: 1;
  background-color: #fff !important;
  top:0
}
