.headerLandingMobile {
    background-color: #FFF;
    display: flex;
    z-index: 999;
    width: 100%;
    position: fixed;
    min-height: 10vh;
    top: 0;
    height: 10vh;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px #00000029;
}
.headerLandingMobile > a {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.headerLandingMobile > a > img{
 height: 5vh;
 margin-right: 2%;
}
.headerLandingMobile > a > p {
    font-size: 2.5vh;
    border-left: solid 1px #333;
    padding-left: 2%;
    text-decoration-line: none;
    color: #333;
}
.headerLandingMobile > div > div > div > span > span{
background-color: #108AC1;
height: 22% !important;

}
.headerLandingMobile > div > div > div > span {
    width: 5vw;
    position: absolute;
    height: 2vh;
    top: 4vh;
    left: 5vw;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.headerLandingMobile> div  > .bm-overlay{
position: absolute;
top: 10vh;
box-shadow: inset 0px 5px 6px 0px rgb(236 236 236);
left: 0;
background-color: #fff !important;
width: 100vw;
height: 90vh;
}
.headerLandingMobile> div  > .bm-menu-wrap{
    left: 0%;
    top: 10vh;
    margin-top: 2%;
    margin-left: 2%;
}

.headerLandingMobile > div  > div  > div  > div  >img {
    width: 110% !important;
    height: 110% !important;
    top: -10vh !important;
    right: 5vw !important;
    position: absolute !important;
}

.headerLandingMobile > div  > div  > div  > div  >button {
   
    height: 130% !important;
    top: -8.5vh !important;
    left: -5vw !important;
    position: absolute !important;
    background-color: #Fff;
}
.menuOptionsMobileLandingPage > h1 > a{
    color: #00AEEF;
    font-size: 3vh;
    text-decoration: none;
    font-weight: 400;
    font-family: 'Fira Sans';
    margin-left: 3%;
    margin-top: 5%;
}
.menuOptionsMobileLandingPage > h2 {
    margin: 0;
}
.menuOptionsMobileLandingPage > h2 > a{
    color: #00AEEF;
    text-decoration: none;
    font-size: 2.8vh;
    font-weight: 400;
    font-family: 'Fira Sans';
    margin-left: 20%;
}
.menuOptionsMobileLandingPage >h1{
    margin-bottom: 0;
}
.menuOptionsMobileLandingPage > h2 > a:hover{
color: #00AEEF;
}
.landing_page_sublinks_active{
    color: #108AC1 !important;
    text-decoration: none !important;
 }
.headerLandingMobile > img {
    z-index: 998;
    height: 4vh;
    position: fixed;
    right: 3%;
}
.menuOptionsMobileLandingPage{
  
    min-height: 30vh;
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
}
.hiddeLogInIcon{
    display: none;
}

.bm-burger-button{
    position: fixed;
    width: 14vw;
    height: 10vh;
    left: 0%;
    top: 0%;
}

.logOutButton{
    z-index: 9999;
    border: none;
    background-color: transparent;
    position: fixed;
    right: 3%;
}