
.content-title {
    width: 100%;
    position: relative;
    height: 100%;
}

.title-documentation img{
    width: 100%;
    height: 100%;
}
.documentation-text{
    color: #00aeef;
    font-weight:500;
    font-size: 2em;
    background-color: #ffffff;
    padding: 0.5rem;
    padding-left: 12rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    position: absolute;
    top: 0%;
    flex-wrap: nowrap;
    flex-direction: row;
    display: inline-block;
    min-width: 5em ;
}
.documentation-text img{
    width: 3em !important;
}