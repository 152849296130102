
.carousel-root{
    margin-left: 6%;
    width: 100%;
    overflow: visible;
    margin-right: 6%;
}

.buttonPrev{
    background-color:#00aeef ;
    height: 5vh;
    width: 5vh;
    border-radius: 50%;
    opacity: 100%;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 999;
}


.buttonNext{
    background-color:#00aeef ;
    height: 5vh;
    width: 5vh;
    border-radius: 50%;
    opacity: 100%;
    border: none;
    cursor: pointer;
    position: absolute;
}

 .arrowPrev {
    -webkit-transform:rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.arrowNext{
    -webkit-transform:rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}

.document-carousel{
    border: solid 1px rgb(172, 172, 172); 
    border-radius: 3px;
   
    margin: 5%;
    padding: 10px;
    height:20vw;
    transition: .5s margin;
}

.document-carousel:hover{
  
  
   border-top: solid 1px #00aeef !important;
   border: solid 1px #00aeef !important;
   border-bottom: solid 1px #00aeef !important;
   margin:3%;
   
   transition: .5s margin;
 

}

.title-carousel{
    border-bottom: 2px solid #00aeef;
    padding-top: 1em;
    padding-bottom: 0.75em;
    color: #00aeef;
    font-weight: 500;
    text-align: left;
    padding-left: 2em;
    padding-top: 3em;
}

.button-content{
   
    align-content: flex-start;
}
.button-content{
    display:flex;
    flex-direction: row-reverse;
}
.button-carousel{
    color: #ffffff;
    background-color:#00aeef;
    padding:1%;
    padding-bottom: 2%;
    box-shadow: 0 0 11px rgba(0,174,239);
    border: none;
    margin-right: 10%;
    margin-bottom: 2%;
    margin-top: 2%;
    border-radius: 10px;
    width: 25%;
    text-decoration: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
 
}

.doubleArrowPrev{
   
    color:white;
   
   
}

.doubleArrowNext{
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    color:white;
   
   
}
.main-slide{
    height: 40vh;
}
.content-carousel{
    height: 55%;
    text-align: left;
    padding-left: 6%;
}
.button-carousel:active{
    color:rgb(236, 236, 236);
}
.carousel-slider{
    overflow: visible !important;
}
