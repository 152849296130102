.landing-page{
  max-width: 100vw;
}
/*

@media (min-width: 1440px){
  .landing-page {
    max-width: 1920px !important;
  }
  }
  */