.footer_landing_container {
    display: flex;
    text-align: center;
    justify-content: center;
    background-color: #fff;

}

.footer-bottom_landing {
    justify-content: space-evenly;
    margin-bottom: 1%;
    min-height: 5vh;
    min-width: 75vw;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    width: 90%;
}

.footer-links_landing>a {
    font-family: 'Fira sans';
    color: #333;
    text-decoration: none;
    font-size: 15px;
    border-right: solid 1px #333;
    margin-right: 1%;
    margin-left: 1%;
    align-items: center;
    padding-right: 2%;
}

.footer-links_landing>a:hover {
    text-decoration: underline;
}

.blue_links_landing>a {
    font-family: 'Fira sans';
    color: #108AC1;
    text-decoration: none;
    font-size:15px;
    margin-right: 1%;
    margin-left: 1%;
    padding-right: .5%;

}

.blue_links_landing>a:hover {
    text-decoration: underline;
}

.footer_links{
    display: inline;
    position: relative;
    top:-9px;
}