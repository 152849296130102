.productMobile{
    max-width: 100vw;
}
.productFirstPartMobile{
    display: flex;
}
.logoLeftMobile{
    display: flex;
    align-items: center;
    width: 27vw;
}
.logoLeftMobile > img{
    width: 100%;
}
.DetailsRightMobile{
    margin-left: 10%;
}
.DetailsRightMobile > a > h3{
    font-size: 4vw;
    color: #333;
    text-align: left;
    margin-top: 0;

    
}
.DetailsRightMobile > p{
    font-size: 3.5vw;
    margin-bottom: 0;
    color: #00AEEF;
    text-align: left;
    margin-top: 0;
    font-weight: 500;
}

.DetailsRightMobile > p >span{
    font-size: 3.5vw;
    color: #333;
    margin-top: 0;   
}
.DescriptionMobile > p{
    font-size: 4vw;
    font-weight: 500;
}