.menu-docs-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 2%;
}
.menu-content{
   
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 2px #ededed47;
    border-radius: 10px;
    width: 20vw;
    height: 20vw;
}
.menu-title{
    display: flex;
}

.menu-title p{
    color: #00aeef;
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
}
.menu-title img{
    width: 100px;
    position: relative;
    left: -15px;
    top: -15px;
}

.menu-list ul{
    list-style: none;
    padding: 0;
 
}
.menu-list button{
    border: none;
    background-color: transparent;
    margin-left: 7px;
    padding-left: 2%;
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
    padding-top: 1%;
    padding-bottom: 1%;
}
.menu-list button:hover{
    background-color: #CBE5F4;
    cursor: pointer;
    border-radius: 25px;
    text-align: left;
    width: 104%;
    box-shadow: 2px -1px 2px #ededed;
    margin-left: -2%;
    padding-left: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
}
 

.menu-list button:hover > after{
    background-color: #CBE5F4;
    cursor: pointer;
    border-radius: 25px;
    text-align: left;
    margin-bottom:2%;
    width: 102%;
    margin-left:-2% !important;
    padding-left: 2%;
}
.menu-display{
    text-align: center;
    width: 45%;
    height: 20vw;
    padding: 2%;
}
.menu-display img{
    width: 2em;
}
.menu-display p{
    color: #333;
    font-weight: 500;
    font-size: 1.2rem;
}
.docs-menu-second-text{
    color: #333;
    font-weight: 400 !important;
    font-size: 1.3rem !important;
}

.MenuDocsActiveTab{
    background-color: #CBE5F4;
    cursor: pointer;
    border-radius: 25px;
    text-align: left;
    margin-bottom:2%;
    width: 102%;
    margin-left:-2% !important;
    padding-left: 2%;
}
